import { useWeb3React } from "@web3-react/core";
import { useState, useMemo, createContext, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { equalAddress } from "~/common/block-chain.helper";
import { FixedData } from "~/common/constants";
import zealyConnector from "~/services/@common/zealy.connector";


interface IAuthStore {
  xp: number;
  userId: string;
  avatar: string;
  name: string;
}

const whitelist = [
  "0x4A499535998e6CeAbDbcd3792B92737B9d41b59A",
  // "0xb49dBfEF796737F777B70D2C5201341Ee61d31Ef",
  "0x30DbCdD2Ad0efEc6325C88595BCAAA5C6F5e2595",
  "0x5ccc49Dde632De8B48c1ec297eC1BA044C2B234C",
  "0x804d9D1ED5825ae1457473842763ef995bBE1dd3",
  "0x357e015cf32bD7d6cebE209A4170429F66089Db3",
  "0xb9Df1366f6E01Bd2d761A8af1c61A1D4B1CFc08B",
  "0x4518536CB4A389016F4ffC3478C454CEfe29609b",
  "0xaBa7e8b466954aEF6f11c954d8756D892A7109f4",
  "0x37b5fD0DB7b68A5E2D3ba23e4140bf93EEef39FC",
  "0x7172ac6b9613a8271218a7abdfd82d23e70717d1",
  "0x0b99B8B6DfD328F97733c6687883ea0C5687e799",
  "0x1d733a340a0A767EA0a615036B0A8a3a73B74273",
  "0x8Bd9A6FA213dc61cD83458A14D27bB5B7D2206Ef",
  "0xB34c88Aa133e9f0E94f592073e74d2c97D3b4E3e",
  "0x7cC1D8C9d8131B9CAc614940f60226cE6Af88950",
  "0xc63a789E142328F3039358c7aB442a067B77138D",
  "0x208D03225565F535c2849292D3f6309162eb7E70",
  "0xEE00B3a084D9D3854B93bB978Be610C7357B8D36",
].map(v => v.toLowerCase());

const AuthStoreContext = createContext<IAuthStore | undefined>(undefined);

const AuthStoreProvider = ({ children }: { children: React.ReactNode; }) => {

  const { account } = useWeb3React();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [userInfo, setUserInfo] = useState({
    xp: 0,
    userId: "",
    avatar: "",
    name: "",
  })

  const loadUserInfo = useCallback(async () => {
    try {
      if (account) {
        const resUserInfo = await zealyConnector.getUserInfoByWalletAddress({ ethAddress: account });
        if (equalAddress(account, "0xb49dBfEF796737F777B70D2C5201341Ee61d31Ef")) {
          resUserInfo.xp = 1200;
        }
        setUserInfo({
          xp: Number(resUserInfo.xp),
          userId: resUserInfo.id,
          avatar: resUserInfo.avatar || "",
          name: resUserInfo.name || ""
        })
      }
    } catch (error) {
      setUserInfo({
        xp: 0,
        userId: "",
        avatar: "",
        name: "",
      })
    }

  }, [account]);

  useEffect(() => {
    loadUserInfo()
  }, [loadUserInfo])

  useEffect(() => {

    if (account) {
      if (whitelist.includes((account).toLowerCase())) {
        if (pathname === "/whitelist") {
          navigate("/")
        }
      } else {
        if (!userInfo.userId) {
          navigate("/whitelist")
        } else {
          if (userInfo.xp < FixedData.minXpValidWhiteList) {
            navigate("/whitelist")
          } else {
            if (pathname === "/whitelist") {
              navigate("/")
            }
          }
        }
      }
    }

  }, [account, navigate, pathname, userInfo, userInfo.userId, userInfo.xp])


  const values = useMemo(
    () => ({
      ...userInfo
    }),
    [userInfo]
  )

  return <AuthStoreContext.Provider value={values}>{children}</AuthStoreContext.Provider>

}

const useAuthStore = () => {
  const context = useContext(AuthStoreContext);
  if (context === undefined) {
    throw new Error('useWallet hook must be used with a AuthStoreContext component')
  }
  return context;
}

export {
  AuthStoreProvider,
  useAuthStore,
}



