import { IEnvConfig } from "../dto";

const config: IEnvConfig = {
  name: "QA",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://i198e9817g.execute-api.ap-southeast-1.amazonaws.com/prod/mvs-dapp"
    }
  },
  applyChainIds: [84531, 97],
  customSMC: {
    97: {
      VAULT_FACTORY: "".trim(),
      BUSD: "0x13b123a205b607Daa99a9D8E21E92d05ad4dFB9F".trim(),
      QUEST_POOL: "".trim(),
      FREE_MINT_POOL: "".trim(),
    },
    84531: {
      VAULT_FACTORY: "0x03181A24dBE0444D1f65E627c7BFa8E9745E49BD".trim(),
      BUSD: "0xFDeacA5bc0E8DC0Df189F233d84578e037f908d7".trim(),
      QUEST_POOL: "0xca8B840932c0Aa34B9E425774c15074B56877fF2".trim(),
      FREE_MINT_POOL: "0xc5b1245a7981A06c0690581e09E3244711f1C93a".trim(),
    }
  }
}

export default config;
